import React from 'react';
import {Link, Redirect} from 'react-router-dom';
import {Row, Col, Container} from 'reactstrap';
import {useDispatch} from 'react-redux';
import useAuth from '../hooks/useAuth';
import './Home.css'; // Import your custom CSS file

const Home = () => {
    const dispatch = useDispatch();
    const auth = useAuth(dispatch);

    if (auth) {
        if (auth.isAdmin) return <Redirect to='/admin' />;
        else if (auth.isTeacher) return <Redirect to='/teacher' />;
        else if (auth.isReader) return <Redirect to='/reader' />;
        else if (auth.isStudent) return <Redirect to='/student' />;
    }

    return (
        <div className='home-container'>
            <Container className='text-center'>
                <img
                    src={`${process.env.PUBLIC_URL}/icons/Image_ nGauge Update.png`}
                    alt='nGauge Update'
                    className='header-icon'
                />
                <h1>nGauge Testing Platform</h1>
                <Row className='home-options'>
                    <Col md={6}>
                        <a
                            href='https://ngauge.appliedpractice.com/online-exam'
                            className='home-option'
                            target='_blank'
                            rel='noopener noreferrer'
                        >
                            <img
                                src={`${process.env.PUBLIC_URL}/icons/Image_ Student Digital.png`}
                                alt='Student Portal - Digital Exam'
                                className='icon'
                            />
                            <div>Student Portal - Digital Exam</div>
                        </a>
                    </Col>
                    <Col md={6}>
                        <a
                            href='https://ngauge.appliedpractice.com/online-exam'
                            className='home-option'
                            target='_blank'
                            rel='noopener noreferrer'
                        >
                            <img src={`${process.env.PUBLIC_URL}/icons/Image_ Student Digital.png`} alt='Student Portal - Digital Exam' className='icon'/>
                            <div>Student Portal - Hybrid Exam</div>
                        </a>
                    </Col>
                    {/* <Col md={6}>
                        <Link to='/ManualResponse' className='home-option'>

                            <img
                                src={`${process.env.PUBLIC_URL}/icons/Image_ Student Upload.png`}
                                alt='Student Portal - Manual Response Upload'
                                className='icon'
                            />
                            <div>Student Portal - Hybrid Exam</div>
                        </Link>
                    </Col> */}
                </Row>
                <Row className='home-options'>
                    <Col md={6}>
                        <Link to='/login' className='home-option'>
                            <img
                                src={`${process.env.PUBLIC_URL}/icons/Image_ Teacher Dashboard.png`}
                                alt='Teacher Dashboard Login'
                                className='icon'
                            />
                            <div>Teacher Dashboard Login</div>
                        </Link>
                    </Col>
                    <Col md={6}>
                        <Link to='/login' className='home-option'>
                            <img
                                src={`${process.env.PUBLIC_URL}/icons/Image_ Admin Dashboard.png`}
                                alt='Admin and Evaluator Dashboard Login'
                                className='icon'
                            />
                            <div>Admin and Evaluator Dashboard Login</div>
                        </Link>
                    </Col>
                </Row>
                <p className='contact-email'>
                    <a href='mailto:info@appliedpractice.com'>info@appliedpractice.com</a>
                </p>
            </Container>
        </div>
    );
};

export default Home;
